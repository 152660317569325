import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { authStore } from '@/stores/auth'
import { nextTick } from 'vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/inloggen',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/contactgegevens',
    name: 'ContactDetails',
    component: () => import(/* webpackChunkName: "contactDetails" */ '../views/ContactDetailsView.vue'),
    meta: {
      authRequired: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired) && !authStore().loggedIn) {
    next('/inloggen')
  } else {
    next()
  }

  nextTick(() => {
    const content = document.querySelector('main.content')
    if (content) {
      content.scrollTop = 0
    }
  })
})

export default router
