<template>
  <template v-if="showMainTemplate">
    <div class="container">
      <mobile-navigation v-if="isMobile" />
      <desktop-navigation v-if="!isMobile" />
      <main class="content">
        <router-view />
        <desktop-footer v-if="!isMobile" />
        <mobile-footer v-if="isMobile" />
      </main>
    </div>
  </template>
  <template v-else>
    <main class="guest-content">
      <router-view />
    </main>
  </template>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue'
import DesktopNavigation from '@/components/base/DesktopNavigation.vue'
import MobileNavigation from '@/components/base/MobileNavigation.vue'
import { useRoute } from 'vue-router'
import { authStore } from '@/stores/auth'
import DesktopFooter from '@/components/base/DesktopFooter.vue'
import MobileFooter from '@/components/base/MobileFooter.vue'

const route = useRoute()

const isMobile = ref(false)

const showMainTemplate = computed(() => {
  return authStore().loggedIn && typeof route.name === 'string' && !['Login'].includes(route.name)
})

onMounted(() => {
  checkIfMobile()
  window.addEventListener('resize', checkIfMobile)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkIfMobile)
})

function checkIfMobile () : void {
  isMobile.value = window.innerWidth <= 768
}
</script>

<style lang="less">
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 250px 1fr;
  grid-template-areas:
    "sidebar content";
  height: 100vh;
}

.content {
  grid-area: content;
  background: #f7f7f7;
  padding: 2rem 2rem 1rem 2rem;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .container {
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "content";
    height: 100vh;
    overflow-y: auto;
  }

  .content {
    padding: 2rem;
    overflow-y: auto;
  }
}
</style>
