<template>
  <header>
    <div class="header-left">
      <MenuOutlined @click="showNavigationDrawer = !showNavigationDrawer" />
    </div>
    <div class="header-right">
      <img src="/img/logos/Logo_Bouwrisk.svg" height="30" />
    </div>
    <a-drawer title="Navigatie" placement="left" width="75%" :body-style="{ padding: 0 }" :visible="showNavigationDrawer" @close="showNavigationDrawer = !showNavigationDrawer">
      <a-menu class="desktop-navigation" mode="inline" v-model:selectedKeys="selectedKeys" @click="navigateTo">
        <a-menu-item key="Dashboard">
          <template #icon>
            <CameraOutlined />
          </template>
          Mijn opname
        </a-menu-item>
        <a-menu-item key="ContactDetails">
          <template #icon>
            <MailOutlined />
          </template>
          Mijn contactgegevens
        </a-menu-item>
        <a-menu-item key="Logout" @click.stop="authStore().logout()">
          <template #icon>
            <LogoutOutlined />
          </template>
          Uitloggen
        </a-menu-item>
      </a-menu>
    </a-drawer>
  </header>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { CameraOutlined, LogoutOutlined, MailOutlined, MenuOutlined } from '@ant-design/icons-vue'
import { authStore } from '@/stores/auth'
import { useRoute, useRouter } from 'vue-router'

const showNavigationDrawer = ref(false)

const selectedKeys = ref<string[]>([])
const route = useRoute()
const router = useRouter()

watch(() => route.name, () => {
  setActiveRoute()
})

onMounted(() => {
  setActiveRoute()
})

function setActiveRoute () : void {
  if (typeof route.name === 'string') {
    switch (route.name) {
      default:
        selectedKeys.value = [route.name]
        break
    }
  }
}

function navigateTo (navItem:{ key:string }) : void {
  if (route.name !== navItem.key && navItem.key !== 'Logout') {
    const keyboard = window.event as KeyboardEvent
    if (keyboard.ctrlKey) {
      const route = router.resolve({ name: navItem.key })
      window.open(route.href, '_blank')
    } else {
      router.push({ name: navItem.key })
    }
  }
  showNavigationDrawer.value = !showNavigationDrawer.value
}
</script>

<style lang="less" scoped>
header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: rgba(0, 0, 0, 0.85);
  padding: 1rem;
  border-bottom: 1px solid #f0f0f0;
}
</style>
