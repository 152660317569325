<template>
  <a-divider />
  <footer>
    <div class="footer-left">
      Copyright &copy; {{new Date().getFullYear()}} - <a href="https://bouwrisk.nl" target="_blank">Bouwrisk B.V.</a>
    </div>
    <div class="footer-right">
      v{{appVersion}}
    </div>
  </footer>
</template>

<script setup lang="ts">
const appVersion = process.env.VUE_APP_VERSION
</script>

<style lang="less" scoped>
footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-left {
    a {
      font-weight: 700;
    }
  }
}
</style>
